<template>
  <div class="bg-white">
    <!-- Hero section -->
    <div class="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
      <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
        <div>
          <div>
            <img class="h-14 w-auto" src="https://www.showdeck.io/index_files/ShowdeckLogo-BlkOnClr.png" alt="Workflow">
          </div>
          <div class="mt-20">

            <div class="mt-6 sm:max-w-xl">
              <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                An online platform for <span class="text-red-500">producing and preserving</span> live performance
              </h1>
              <p class="mt-6 text-xl text-gray-500">
                We can’t wait to open this show. <br>Until then, sign up to our mailing list to stay in the loop or to get a chance to be one of our beta testers. Read on, for more information.
              </p>
            </div>
            <div class="mt-12 sm:max-w-lg sm:w-full sm:flex">

              <div class="mt-4 sm:mt-0 sm:ml-3">
                <a href="https://showdeck.typeform.com/to/lyr8eydq" type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-red-500 text-base font-medium text-white shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10">Sign me up</a>
                <!--<button data-tf-slider="lyr8eydq" data-tf-width="550" data-tf-iframe-props="title=Lead Generation" class="block w-full rounded-md border border-transparent px-5 py-3 bg-red-500 text-base font-medium text-white shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:px-10" data-tf-loaded="true">Sign me up</button> -->
              </div>
            </div>
            <div class="mt-6">
              <div class="inline-flex items-center divide-x divide-gray-300" style="visibility: hidden;">
                <div class="flex-shrink-0 flex pr-5">
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                </div>
                <div class="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3"><span class="font-medium text-gray-900">Rated 5 stars</span> by over <span class="font-medium text-red-500">5 beta users (incl. owners &amp; developers)</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sm:mx-auto sm:max-w-3xl sm:px-6">
        <div class="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div class="hidden sm:block">
            <div class="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
            <svg class="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404" height="392" fill="none" viewBox="0 0 404 392">
              <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"></rect>
                </pattern>
              </defs>
              <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
            </svg>
          </div>
          <div class="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
            <img class="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none" src="https://www.showdeck.io/index_files/welcome.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- Testimonial/stats section -->
    <div class="relative mt-20">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div class="relative sm:py-16 lg:py-0">
          <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
            <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
              <defs>
                <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"></rect>
                </pattern>
              </defs>
              <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"></rect>
            </svg>
          </div>
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
            <!-- Testimonial card-->
            <div class="relative pb-10 rounded-2xl shadow-xl overflow-hidden">
              <div class="absolute inset-0 bg-red-500 mix-blend-multiply"></div>
              <div class="absolute inset-0 bg-gradient-to-t from-red-600 via-red-600 opacity-90"></div>
              <div class="relative px-8">
                <div>
                </div>
                <blockquote class="mt-8">
                  <div class="relative text-white md:flex-grow">
                    <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-400" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
                    </svg>
                    <p class="relative">
                      I am very impressed with Showdeck, a product that pulls together the entire portfolio of departments and challenges that productions deal with. Currently our industry currently relies on a combination of various software for its operations but Showdeck draws all these together and does not require teams to clutter up their workflow with assorted programs they might not otherwise use. Furthermore, by collating all the information in one place where it is coherently updated, all staff remain current.</p>
                    <p class="mt-5">Few individuals combine such comprehensive knowledge of the mechanisms of the theatre industry with a successful career in making shows as Fiffi. He is an extremely respected creative in his own right but furthermore a consultant who has worked around the world and has learnt from the strengths and weaknesses of multiple countries. His knowledge of how theatre is made internationally is considerable and this makes him very well suited to create software that will be of use internationally at all types and scale of performance.
                    </p>
                  </div>
                  <footer class="mt-4">
                    <p class="text-base font-semibold text-red-200">David Evans<br> Co-Chair Association of British Theatre Technicians (ABTT) <br> Ex Chair Production Manager’s Forum (PMF)</p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          <!-- Content area -->
          <div class="pt-12 sm:pt-16 lg:pt-20">

            <h2 class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl text-center">
              Showdeck
            </h2>
            <p class="text-center text-gray-600">/’ʃaʊ̯dek/</p>
            <p class="text-gray-500 text-italic" style="margin-left:-20px">noun</p>
            <ol class="list-decimal">
              <li class="mb-5">A custom built floor on top of a theatre stage, on the inside containing technical infrastructure such as revolves, automation tracks (for motorised scenery), concealed lighting or smoke effects, sound monitors, fans, pyrotechnics, traps etc.; on the outside displaying the design and visual dramaturgy of the production it is intended for. On some large scale shows, the show deck completely replaces the existing theatre stage, which is put back in place when the show has finished it's run. </li>
              <li>A platform for your production.</li>
            </ol>
            <!-- Stats section -->
            <div class="mt-10">
              <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
                <div class="border-t-2 border-gray-100 pt-6">
                  <dt class="text-base font-medium text-gray-500">Founded</dt>
                  <dd class="text-3xl font-extrabold tracking-tight text-gray-900">2020</dd>
                </div>

                <div class="border-t-2 border-gray-100 pt-6">
                  <dt class="text-base font-medium text-gray-500">Employees</dt>
                  <dd class="text-3xl font-extrabold tracking-tight text-gray-900">5</dd>
                </div>

                <div class="border-t-2 border-gray-100 pt-6">
                  <dt class="text-base font-medium text-gray-500">Beta Users</dt>
                  <dd class="text-3xl font-extrabold tracking-tight text-gray-900">5</dd>
                </div>

                <div class="border-t-2 border-gray-100 pt-6">
                  <dt class="text-base font-medium text-gray-500">Raised</dt>
                  <dd class="text-3xl font-extrabold tracking-tight text-gray-900">30M ISK</dd>
                </div>
              </dl>
              <div class="mt-10">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Logo cloud section -->
      <div class="mt-32">
        <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div class="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
            <div>
              <h2 class="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Backed by renowned funds and soon, <span class="text-red-500">investors too!</span>
              </h2>
              <p class="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                Showdeck has been developed with the help of Icelandic funds and we are in the process of bringing on board investors that share our vision of building the world’s greatest platform for producing and preserving live performance.
              </p>
              <div class="mt-6">
                <a href="mailto:showdeck@showdeck.io" class="text-base font-medium text-red-500">
                  Interested? →
                </a>
              </div>
            </div>
            <div class="mt-12 grid grid-cols-2 gap-2 mx-auto">
              <div class="w-full rounded bg-gray-50">
                <img class=" " src="https://www.showdeck.io/index_files/techdev-logo.png" alt="Tækniþróunarsjóður">
              </div>

              <div class="w-full rounded bg-gray-50">
                <img class="" src="https://www.showdeck.io/index_files/nsn-logo.png" alt="NSN">
              </div>

            </div>
          </div>
        </div>
      </div>

      <section aria-labelledby="team-heading" class="bg-white mt-12 pt-24">
        <div class="bg-red-500">
          <div class="mx-auto py-7 px-4 max-w-7xl sm:px-6 lg:px-8 lg:pt-24 lg:py-12">
            <div class="space-y-12">
              <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                <h2 class="text-4xl font-extrabold text-white tracking-tight sm:text-4xl">Dramatis Personae</h2>
                <p class="text-xl text-white">Lord, we know what we are, but know not what we may be.</p>
              </div>
              <ul role="list" class="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
                <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
                  <div class="space-y-6 xl:space-y-10">
                    <img class="mx-auto h-30 w-30 rounded-full xl:w-40 xl:h-40" src="https://www.showdeck.io/index_files/fiffi.png" alt="">
                    <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div class="font-medium text-lg leading-6 space-y-1">
                        <h3 class="">Fridthjofur Thorsteinsson</h3>
                        <p class="text-red-600">Founder &amp; CEO</p>
                      </div>

                    </div>
                  </div>
                  <p class="mt-5">Fridthjofur, better known as Fiffi - for obvious reasons, is an established lighting designer and theatre consultant in the UK, Iceland and worldwide. He has developed the idea behind Showdeck for a number of years, based on his industry experience.</p>
                </li>


                <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
                  <div class="space-y-6 xl:space-y-10">
                    <img class="mx-auto h-30 w-30 rounded-full xl:w-40 xl:h-40" src="https://www.showdeck.io/index_files/aegir.png" alt="">
                    <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div class="font-medium text-lg leading-6 space-y-1">
                        <h3 class="">Ægir Finnsson</h3>
                        <p class="text-red-600">Co-Founder &amp; CTO</p>
                      </div>

                    </div>
                  </div>
                  <p class="mt-5">Aegir’s extensive experience of software and startup development is key to Showdeck’s birth and success. Amongst his other projects are Computer Vision and Parka, that collectively make image analysis and practical use a bliss for the Icelandic public.</p>
                </li>


                <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
                  <div class="space-y-6 xl:space-y-10">
                    <img class="mx-auto h-30 w-30 rounded-full xl:w-40 xl:h-40" src="https://www.showdeck.io/index_files/arnar.png" alt="">
                    <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div class="font-medium text-lg leading-6 space-y-1">
                        <h3 class="">Arnar Leó Ólafsson</h3>
                        <p class="text-red-600">Software developer</p>
                      </div>

                    </div>
                  </div>
                  <p class="mt-5">To code or not to code is never a question for Arnar, who concentrates on CSS and front end development. As well as being a certified coffee specialist he has explored AI/machine learning but maintains machines will never be able to make the perfect cup of coffee.  Arnar is co-founder of Álafoss FC. </p>
                </li>


                <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
                  <div class="space-y-6 xl:space-y-10">
                    <img class="mx-auto h-30 w-30 rounded-full xl:w-40 xl:h-40" src="https://www.showdeck.io/index_files/katrin.png" alt="">
                    <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div class="font-medium text-lg leading-6 space-y-1">
                        <h3 class="">Katrín Guðbjartsdóttir</h3>
                        <p class="text-red-600">Performance specialist / UX/UI</p>
                      </div>

                    </div>
                  </div>
                  <p class="mt-5">With a particular passion for the subject, Katrin has employed her skills and experience in devised theatre to designing the look, feel and processes behind Showdeck. She is also the guitarist of the Icelandic band Hórmónar. </p>
                </li>


                <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
                  <div class="space-y-6 xl:space-y-10">
                    <img class="mx-auto h-30 w-30 rounded-full xl:w-40 xl:h-40" src="https://www.showdeck.io/index_files/stebbi.png" alt="">
                    <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div class="font-medium text-lg leading-6 space-y-1">
                        <h3 class="">Stefán Laxdal</h3>
                        <p class="text-red-600">Software developer</p>
                      </div>

                    </div>
                  </div>
                  <p class="mt-5">Before turning software developer, Stefan has had his share of the gig-biz as a singer, guitarist, songwriter and a chef - so he fully understands why we want to organise it. Cutting through front and backend mutations Stefan makes sure the content is delivered.</p>
                </li>


                <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
                  <div class="space-y-6 xl:space-y-10">
                    <img class="mx-auto h-30 w-30 rounded-full xl:w-40 xl:h-40" src="https://www.showdeck.io/index_files/stefan.png" alt="">
                    <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div class="font-medium text-lg leading-6 space-y-1">
                        <h3 class="">Stefán Þór Þorgeirsson</h3>
                        <p class="text-red-600">Performance specialist / UX/UI</p>
                      </div>

                    </div>
                  </div>
                  <p class="mt-5">A unique actor/industrial engineer combo, Stefán provides a methodical approach to structuring our data in an industry that is anything but! Stefán enjoys the great outdoors, guides for National Geographic and runs his apocalyptic podcast „Heimsendir!”</p>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </section>


      <!-- This example requires Tailwind CSS v2.0+ -->
      <section aria-labelledby="faq-heading" class="bg-white">
        <div class="max-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div class="max-w-xl">
            <h2 id="faq-heading" class="text-2xl font-extrabold tracking-tight text-gray-900">Frequently asked questions</h2>
            <p class="mt-4 text-base text-gray-500">Here are answers to some of the most frequently asked questions about Showdeck. If you have any other questions that you can't find the answer to, please <a href="mailto:showdeck@showdeck.io" class="font-medium text-indigo-600 hover:text-indigo-500">drop us a line</a> and we'll get back to you soon.</p>
          </div>

          <dl class="mt-12 grid grid-cols-1 gap-y-10 sm:mt-16 md:grid-cols-2 md:gap-x-6 lg:grid-cols-3">
            <div>
              <dt class="text-base font-medium text-gray-900">
                What does it do?
              </dt>
              <dd class="mt-3 text-sm text-gray-500">
                Showdeck will help you create, organise and preserve any information you might need in order to produce and preserve your live performance. Less confusion, less missed opportunities, less lost information and more effective work.
              </dd>
            </div>
            <div>
              <dt class="text-base font-medium text-gray-900">
                Who is it for?
              </dt>
              <dd class="mt-3 text-sm text-gray-500">
                If your work is in any way related to live performance, Showdeck is for you. Whether performers, directors, designers, managers, technicians, marketing, photographers, venue operators, freelance or casual staff etc., we aim to make your work simpler and easier.
              </dd>
            </div>
            <div>
              <dt class="text-base font-medium text-gray-900">
                How?
              </dt>
              <dd class="mt-3 text-sm text-gray-500">
                Showdeck gives you general and specific tools, similar to the services you may already be using to produce your work. By specialising in performance and keeping everything in one place we achieve automation and synergy. Like with most things, the more you put in the more you get out.
              </dd>
            </div>
            <div>
              <dt class="text-base font-medium text-gray-900">
                It does not sound very artistic...
              </dt>
              <dd class="mt-3 text-sm text-gray-500">
                Showdeck is a production management platform that works best when everyone involved take part and share the workload. By modernising working methods we will be able to automate some of the tedious organisational work, allowing more time for creative work.
              </dd>
            </div>
            <div>
              <dt class="text-base font-medium text-gray-900">
                What subscription is available?
              </dt>
              <dd class="mt-3 text-sm text-gray-500">
                There will be separate subscription categories (including unpaid ones) for individuals, companies, venues, productions etc. We are laying out pricing options at the moment and aim to keep it sensible. We understand the market live performance operates in.
              </dd>
            </div>
            <div>
              <dt class="text-base font-medium text-gray-900">
                When can I sign up?
              </dt>
              <dd class="mt-3 text-sm text-gray-500">
                We are currently accepting sign ups to our mailing list, to let you know when we are ready to sign users up to the system. If you would like to be a beta tester, you can notify us in the sign up process and we will consider you. There will be no recalls - we will simply let you know when you got the part.
              </dd>
            </div>


            <!-- More questions... -->
          </dl>
        </div>
      </section>


      <!-- CTA section -->
      <div class="relative mt-15 sm:mt-15 sm:py-16">
        <div aria-hidden="true" class="hidden sm:block">
          <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
          <svg class="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
            <defs>
              <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"></rect>
              </pattern>
            </defs>
            <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"></rect>
          </svg>
        </div>
        <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div class="relative rounded-2xl px-6 py-10 bg-red-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                <path class="text-red-400 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"></path>
                <path class="text-red-600 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"></path>
              </svg>
            </div>
            <div class="relative">
              <div class="sm:text-center">
                <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  Get notified when we’re launching.
                </h2>
                <p class="mt-6 mx-auto max-w-2xl text-lg text-red-100">We can't wait to open this show but we're not quite ready just yet. In the meantime we are accepting a small number of beta testers - or you could sign up for our mailing list and we'll let you know when out o' the hat, it's that big first night!
                </p>
              </div>
              <div class="mt-12 sm:mx-auto sm:max-w-lg text-center">
                <div class="">
                  <a href="https://showdeck.typeform.com/to/lyr8eydq" class="block w-full rounded-md border border-transparent px-5 py-3 bg-white text-red-500 font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-500 sm:px-10">Notify me</a>
                  <!-- <button data-tf-slider="lyr8eydq" data-tf-width="550" data-tf-iframe-props="title=Lead Generation" class="block w-full rounded-md border border-transparent px-5 py-3 bg-white text-base font-medium text-red-500 shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-500 sm:px-10" data-tf-loaded="true">Notify me</button>-->
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </template>

 <script>

 export default {
   name: "About",
 }
 </script>

 <style scoped>

 </style>